<template>
  <div class="app-box">
    <x-scroll-view ref="scrollView" :bottom="0.66" @scrollBarPos="scrollBarPos">
      <div class="visit-max-title">拜访人员</div>
      <x-form-box>
        <x-form-input label="姓名" placeholder="请选择" required disable v-model="name">
          <i v-if="type=='new'" class="name-tag-icon" @click="namTagEvent"></i>
        </x-form-input>
        <x-form-input label="联系电话" placeholder="" disable v-model="telephone"></x-form-input>
        <x-form-input label="职务" placeholder="" disable v-model="positon"></x-form-input>
      </x-form-box>
      <div class="visit-max-title">拜访概要<i class="title-dot">*</i></div>
      <x-form-box>
        <x-map v-if="customerData" :center="[customerData.latitude,customerData.longitude]" :endCenter="[xhrData.endLatitude,xhrData.endLongitude]" @change="mapChange"></x-map>
        <div class="client-box">
          <div class="client-title-box">
            <label>客户地址</label>
            <button :class="{'client-no-data':!isVisit}" @click="visitBtn">{{isStartVisit?"开始拜访":"结束拜访"}}</button>
          </div>
          <p v-if="customerData" :class="{'client-txt-no-data':!customerData.address}">{{customerData.address||"请先采集客户地址信息"}}</p>
        </div>
        <x-form-position label="开始" :isRed="xhrData.scopeIsOkBegin" disable :address="xhrData.beginAddress" :dates="xhrData.beginTime"></x-form-position>
        <x-form-position v-if="!xhrData.forgetEnd" label="结束" :isRed="xhrData.scopeIsOkEnd" disable :address="xhrData.endAddress" :dates="xhrData.endTime"></x-form-position>
        <x-form-position v-else label="结束" isRed address="忘打卡"></x-form-position>
      </x-form-box>
      <div class="visit-max-title">拜访详情</div>
      <x-form-box>
        <x-form-select label="拜访目的" :slots="VisitPurposeEnum" @change="visitPurposeChange" :bindValue="xhrData.visitPurpose"></x-form-select>
        <x-form-input v-if="xhrData.visitPurpose==20" label="活跃度" v-model="xhrData.liveness"></x-form-input>
        <!-- <x-form-input label="其他拜访目的" v-model="xhrData.otherPurpose"></x-form-input> -->
        <x-form-textarea label="具体描述" v-model="xhrData.visitDescripiton"></x-form-textarea>
        <x-form-images @change="visitImgChange" :imgList="xhrData.visitImgList"></x-form-images>
      </x-form-box>
      <div class="visit-max-title">业务机会</div>
      <x-form-box>
        <!-- <x-form-select label="商城开户" :slots="shopOpenEnum" @change="shopOpenChange" :bindValue="xhrData.shopOpen"></x-form-select> -->
        <x-form-drugs-list label="药品采购" @change="drugsChange" :list="xhrData.medicineInfoVoList"></x-form-drugs-list>
        <x-form-device-list label="设备采购" @change="deviceChange" :list="xhrData.facilityDtoList"></x-form-device-list>
        <x-form-tag label="其他机会" :slots="VisitOtherChanceEnum" :tagList="xhrData.otherChances" @change="visitOtherChanceChange"></x-form-tag>
        <x-form-textarea label="具体描述" v-model="xhrData.businessChanceDesc"></x-form-textarea>
        <x-form-images @change="businessChanceImgChange" :imgList="xhrData.businessChancheImgList"></x-form-images>
      </x-form-box>
    </x-scroll-view>
    <x-footer-box :type="type" :isDisabledBtn="isDisabledBtn" @saveEvent="saveClick" @delEvent="delClick"></x-footer-box>
  </div>
</template>

<script>
import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import formSelect from "@/components/formControl/formSelect";
import formPosition from "@/components/formControl/formPosition";
import formTextArea from "@/components/formControl/formTextArea";
import formDrugsList from "@/components/formControl/formDrugsList";
import formDeviceList from "@/components/formControl/formDeviceList";
import formTag from "@/components/formControl/formTag";
import formImages from "@/components/formControl/formImages";
import footerBox from "@/components/footerBox";
import scrollView from "@/components/scrollView";
import map from "@/components/visitRecord/map";
import { cachePage } from "@/script/mixins/cachePage";
import { getNowTime } from "@/script/tools";
import { getCustomer } from "@/api/customerCenter";
import { getVisit, addVisit, delVisit, editVisit } from "@/api/visitRecord";
import { qqMapGeocoder } from "@/api/base";
import { ajaxBack } from "@/script/mixins/ajaxBack";
import { MessageBox } from "mint-ui";

export default {
  name: "visitRecordEdit",
  data () {
    return {
      scrollBarTop: 0,
      isDisabledBtn: true,
      isStartVisit: true,
      isVisit: true,
      type: this.$route.params.type,
      telephone: null,
      positon: null,
      name: null,
      latLng: null,
      scopeIs: null,
      xhrData: {
        customerId: this.$route.params.id,
        beginAddress: null,
        beginLatitude: null,
        beginLongitude: null,
        beginTime: null,
        scopeIsOkBegin: null,
        endAddress: null,
        endLatitude: null,
        endLongitude: null,
        endTime: null,
        scopeIsOkEnd: null,
        staffId: null,
        visitPurpose: null,
        liveness: null,
        // shopOpen: null,
        visitDescripiton: null,
        businessChanceDesc: null,
        otherChances: [],
        otherPurpose: null,
        medicineIdList: [],
        facilityDtoList: [],
        businessChanceImgList: [],
        visitImgList: []
      },
      customerData: null,
      // shopOpenEnum: [{
      //   flex: 1,
      //   values: [{
      //     code: 1,
      //     name: "是"
      //   }, {
      //     code: 0,
      //     name: "否"
      //   }],
      //   textAlign: "center",
      //   defaultIndex: 0
      // }],
      VisitPurposeEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }],
      VisitOtherChanceEnum: []
    };
  },
  mixins: [cachePage, ajaxBack],
  created () {
    document.title = "";
    document.title = "拜访详情";
    this.setEnum();
  },
  methods: {
    visitBtn () {
      if (this.isVisit) {
        if (this.scopeIs) {
          this.cardEvent();
        } else {
          MessageBox({
            title: null,
            message: "当前不在打卡范围内，确定打卡?",
            showCancelButton: true
          }).then(action => {
            action == "confirm" && this.cardEvent();
          });
        }
      }
    },
    cardEvent () {
      qqMapGeocoder({
        latitude: this.latLng[0],
        longitude: this.latLng[1]
      }).then(xhr => {
        if (this.isStartVisit) {
          this.xhrData.beginLatitude = this.latLng[0];
          this.xhrData.beginLongitude = this.latLng[1];
          this.xhrData.beginAddress = xhr.data.result.address;
          this.xhrData.beginTime = getNowTime();
          this.xhrData.scopeIsOkBegin = this.scopeIs;
          addVisit(this.xhrData).then(xhr => {
            this.xhrData.id = xhr.data;
          });
        } else {
          this.xhrData.endLatitude = this.latLng[0];
          this.xhrData.endLongitude = this.latLng[1];
          this.xhrData.endAddress = xhr.data.result.address;
          this.xhrData.endTime = getNowTime();
          this.xhrData.scopeIsOkEnd = this.scopeIs;
          editVisit(this.xhrData);
        }
        this.setIsVisit();
      });
    },
    mapChange (item) {
      this.latLng = item.latLng;
      this.scopeIs = item.scopeIs;
    },
    visitImgChange (item) {
      this.xhrData.visitImgList = [];
      item.map(val => {
        this.xhrData.visitImgList.push(val.id);
      });
    },
    businessChanceImgChange (item) {
      this.xhrData.businessChanceImgList = [];
      item.map(val => {
        this.xhrData.businessChanceImgList.push(val.id);
      });
    },
    scrollBarPos (scrollBarTop) {
      this.scrollBarTop = scrollBarTop;
    },
    deviceChange (item) {
      this.$refs.scrollView.setScrollTopPos(this.scrollBarTop);
      this.xhrData.facilityDtoList = item;
    },
    drugsChange (item) {
      this.$refs.scrollView.setScrollTopPos(this.scrollBarTop);
      this.xhrData.medicineIdList = [];
      item.map(val => {
        this.xhrData.medicineIdList.push(val.id);
      });
    },
    // shopOpenChange (item) {
    //   this.xhrData.shopOpen = item.code;
    // },
    visitPurposeChange (item) {
      this.xhrData.visitPurpose = item.code;
    },
    visitOtherChanceChange (item) {
      this.xhrData.otherChances = [];
      item.map(val => {
        this.xhrData.otherChances.push(val.code);
      });
    },
    setEnum () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson"));
      Object.keys(configJson.VisitPurposeEnum).forEach(key => {
        this.VisitPurposeEnum[0].values.push({
          code: key,
          name: configJson.VisitPurposeEnum[key]
        });
      });
      Object.keys(configJson.VisitOtherChanceEnum).forEach(key => {
        this.VisitOtherChanceEnum.push({
          code: key,
          name: configJson.VisitOtherChanceEnum[key],
          check: false
        });
      });
      this.type == "new" && this.getCustomerData(this.$route.params.id);
      this.type == "edit" && this.getData();
    },
    getData () {
      getVisit({
        id: this.$route.params.id
      }).then(xhr => {
        this.xhrData = xhr.data;
        this.xhrData.scopeIsOkBegin = parseInt(this.xhrData.scopeIsOkBegin);
        this.xhrData.scopeIsOkEnd = parseInt(this.xhrData.scopeIsOkEnd);
        this.name = this.xhrData.staffVo.name;
        this.telephone = this.xhrData.staffVo.telephone;
        this.positon = this.xhrData.staffVo.positon;
        this.getCustomerData(this.xhrData.customerId);
      });
    },
    getCustomerData (id) {
      getCustomer({
        customerId: id
      }).then(xhr => {
        this.customerData = xhr.data;
        this.setIsVisit();
      });
    },
    setIsVisit () {
      if (this.customerData.address && !this.xhrData.forgetEnd) {
        if (this.xhrData.beginAddress && this.xhrData.endAddress) {
          this.isVisit = false;
        } else {
          this.isVisit = !!this.xhrData.staffId;
        }
      } else {
        this.isVisit = false;
      }
    },
    namTagEvent () {
      this.$router.push({
        path: `/visitRecord/pick/${this.$route.params.id}`
      });
    },
    saveClick () {
      if (!this.isDisabledBtn) {
        if (this.type == "new" && !this.xhrData.id) {
          addVisit(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, "保存成功！");
          });
        } else {
          editVisit(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, this.type == "new" ? "保存成功！" : "修改成功！");
          });
        }
      }
    },
    delClick () {
      delVisit({
        id: this.$route.params.id
      }).then(xhr => {
        this.ajaxBack(xhr, "删除成功！", -2);
      });
    }
  },
  watch: {
    xhrData: {
      handler (newVal) {
        this.isDisabledBtn = !newVal.staffId || !this.xhrData.beginAddress;
        this.isStartVisit = !(newVal.beginLatitude && newVal.beginLongitude);
        this.isVisit = !!this.xhrData.staffId;
      },
      deep: true
    },
    $route () {
      const searchValue = this.$store.getters.getSearchValue;
      if (searchValue) {
        this.name = searchValue.name;
        this.positon = searchValue.positon || searchValue.otherPositon;
        this.telephone = searchValue.telephone || searchValue.fixedLineTelephone;
        this.xhrData.staffId = searchValue.id;
        this.$store.commit("setSearchValue", null);
      }
    }
  },
  components: {
    [formBox.name]: formBox,
    [formInput.name]: formInput,
    [formSelect.name]: formSelect,
    [formPosition.name]: formPosition,
    [formTextArea.name]: formTextArea,
    [formDrugsList.name]: formDrugsList,
    [formDeviceList.name]: formDeviceList,
    [formTag.name]: formTag,
    [formImages.name]: formImages,
    [footerBox.name]: footerBox,
    [scrollView.name]: scrollView,
    [map.name]: map
  }
};
</script>

<style scoped>
.title-dot {
  margin-left: 0.04rem;
  color: #f75959;
}
.client-box {
  margin-bottom: 0.1rem;
}
.client-title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.client-title-box label {
  display: inline-table;
  padding: 0.02rem 0.06rem;
  border-radius: 0.02rem;
  background-color: rgba(43, 130, 247, 0.1);
  line-height: 1;
  font-size: 0.12rem;
  color: #2b82f7;
}
.client-title-box button {
  border: 0;
  font-size: 0.15rem;
  color: #ffffff;
  background-color: #2b82f7;
  box-shadow: 0 0.02rem 0.02rem 0 #d0e8ff;
  border-radius: 0.24rem;
  line-height: 1;
  padding: 0.08rem 0.16rem;
}
.client-title-box .client-no-data {
  background-color: #9fc7fc;
  box-shadow: 0 0.02rem 0.02rem 0 #d0e8ff;
}
.client-box p {
  line-height: 0.16rem;
  width: 2.7rem;
  font-size: 0.14rem;
  color: #737885;
  margin-top: 0.08rem;
}
.client-box .client-txt-no-data {
  color: #c6cad1;
}
.name-tag-icon {
  margin-left: 0.1rem;
  width: 0.15rem;
  height: 0.18rem;
  background: url('~@images/name_tag_icon.png') no-repeat center/0.15rem 0.18rem;
}
</style>
